import React, {useState, useEffect, useContext} from "react";
import Layout from "../../components/layout.js";
import Link from "gatsby-link";
import Button from "../../components/Button";
import api from "../../api.js";
import { AuthContext } from "../../context/GlobalContextProvider"; 

function EditarParceiros() {
  const context = useContext(AuthContext)

  const testecss = "font-bold text-center base-golden-center my-16"

  const [parceiros, setParceiros] = useState(null)
  const [selectImage, setSelectedImage] = useState(null)
  const [newPartnerName, setNewPartnerName] = useState("")

  useEffect(async () => {
    console.log(await context)
  }, [])

  const getData = async () => {
    var getImages = await api.get('/api/parceiros?populate=*')
    const imageData = getImages.data
    
    if(imageData){
      setParceiros(imageData)
    }
  }
  const createPartner = async (e) => {
    e.preventDefault();

    const formData = new FormData()

    formData.append('files', selectImage)

    api.post('/api/upload', formData)
    .then((response)=>{
      console.log(response)

      const imageData = response.data[0].id

      api.post('/api/parceiros', {data:{Name: newPartnerName, Logo: imageData}})
      .then((response)=>{
        console.log(response)
      }).catch((error)=>{
        console.log(error)
      })
    }).catch((error)=>{
      console.log(error)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Layout>
      <h1 className={testecss}>
        Editar Parceiros
      </h1>
        <h2 className="mb-10">Novo Parceiro:  </h2>  
        <div className="card p-10 mb-20">
        <div className="grid grid-cols-2 gap-4">
          {selectImage &&
          <img src={URL.createObjectURL(selectImage)} alt="not found"/>
          }          
          <div className="flex flex-col gap-4">
            <p>
            <input 
              className="w-1/2"
              type="file"
              name="newPartnerImage"
              onChange={(e) => {
                console.log(e.target.files[0])
                setSelectedImage(e.target.files[0])
              }}
            />(Utilize imagens com resolução 4:1)</p>
            <p>Nome: <input type="text" name="newPartnerName" onChange={(e) => setNewPartnerName(e.target.value)}/></p>
            <Button onClick={(e) => createPartner(e)}>Gerar</Button>
          </div>
        </div>
          
        </div>     
        <h2 className="mb-10">Parceiros:  </h2> 
        <div className="grid grid-cols-1 gap-4 mb-20">        
        {parceiros != null ? parceiros?.data.map( item => (
          <div className="card p-10">
            <h3 className="mb-5">{item?.attributes?.Name}</h3>
              <div className="grid grid-cols-2 gap-4">
                <img src={"http://localhost:1337" + item?.attributes?.Logo?.data?.attributes?.url} 
              /> 
              <div className="flex flex-col gap-4">
                <Button className="w-1/2">Excluir</Button>
                <Button className="w-1/2">Editar Imagem</Button>
                <Button className="w-1/2">Editar Nome</Button>
              </div>
              </div>   
          </div>
          )) : null}
          <Button>Adicionar Parceiro</Button>
        </div>
    </Layout>
  );
}

export default EditarParceiros;